<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6042 21.7102C14.6343 21.8255 14.4857 21.8346 14.3714 21.8009L12.2799 21.1832C12.1192 21.1358 11.9483 21.1358 11.7877 21.1832L9.57743 21.8359C9.53376 21.8488 9.45201 21.854 9.4222 21.8196C9.37402 21.764 9.41138 21.7107 9.44422 21.6449L11.8553 16.8121C11.857 16.8087 11.8652 16.7967 11.8673 16.7936C11.9066 16.7382 12.0442 16.739 12.0835 16.7944C12.0855 16.7973 12.0932 16.8086 12.0948 16.8118L14.5295 21.5828C14.547 21.6171 14.5944 21.6729 14.6042 21.7102Z"
    />
    <path
      d="M11.2244 1.48036L1.0943 21.7406C0.751599 22.426 1.39931 23.1872 2.13072 22.9586L7.98735 21.1284L11.7578 14.1069C11.8627 13.9631 12.052 13.9631 12.1568 14.1069L16.0179 21.129L21.8725 22.9586C22.604 23.1872 23.2517 22.426 22.909 21.7406L12.7789 1.48036C12.4586 0.839881 11.5446 0.839881 11.2244 1.48036Z"
    />
  </svg>
</template>
